import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import { FaPaperPlane } from "react-icons/fa6";
import { FaRegEnvelope } from "react-icons/fa6";
import { HiOutlineLocationMarker } from "react-icons/hi";

const ContactPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    useEffect(() => {
        AOS.init({
            duration: 1500,
            easing: 'ease',
        });
    }, []);
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
    };

    return (
        <>
        <div className='flex justify-center px-6 md:px-8'>
        <div className="flex flex-col md:flex-row justify-center mx-1  my-4 md:my-24 w-[1000px] fontjost" data-aos="fade-up">
            <div className="w-full p-0 md:p-10  text-black mb-8 md:mb-0">
                {/* <h2 className="text-lg font-semibold mb-4 mt-10 flex items-center justify-start"><FiPhone className='text-[#2DC3D0] text-[1.5rem]' /> <p className='pl-2'>Call Us</p></h2> */}
                {/* <p>+447883369018</p> */}
                <h2 className="text-lg font-semibold mb-4 mt-4 flex items-center justify-start"><HiOutlineLocationMarker className='text-[#2DC3D0] text-[1.5rem] font-bold' /> <p className='pl-2'>Location</p></h2>
                <p>United Kingdom, Bolton</p>

                <h2 className="text-lg font-semibold mb-4 mt-4 flex items-center justify-start"><FaRegEnvelope className='text-[#2DC3D0] text-[1.5rem]' /> <p className='pl-2'>Email</p></h2>
                <p>rabiraza110@gmail.com</p>
            </div>
            <div className="w-full p-10 border bg-[#4BD9E55C]" data-aos="fade-up">
                <h2 className="text-[26px] font-bold mb-4 text-black text-center">Contact Us</h2>
                <form onSubmit={handleSubmit}>
                    <input type="text" placeholder="Enter your Name" id="name" name="name" value={formData.name} onChange={handleChange} className="w-full p-2 mb-4 border rounded-[6px] outline-none hover:shadow-xl transition-all duration-300 ease-in-out shadow-md" required />
                    <input type="email" placeholder="Enter a valid Email Address" id="email" name="email" value={formData.email} onChange={handleChange} className="w-full  p-2 mb-4 border-none rounded-[6px] outline-none hover:shadow-xl transition-all duration-300 ease-in-out shadow-md" required />
                    <textarea id="message" placeholder="Message" name="message" value={formData.message} onChange={handleChange} className="w-full p-2 mb-4 border border-gray-300 rounded-[6px] outline-none hover:shadow-xl transition-all duration-300 ease-in-out shadow-md" rows="4" required></textarea>
                    <div className='flex items-center justify-end'>
                    <button type="submit" className="bg-white hover:bg-gray-100 text-black px-4 py-2 rounded-md hover:shadow-xl transition-all duration-300 ease-in-out shadow-md" >
                        Send Message <FaPaperPlane className='text-[#2DC3D0] inline-block'/>
                    </button>
                    </div>
                </form>
            </div>
        </div>
        </div>
        </>
    );
};

export default ContactPage;