import React from 'react'

function Header() {
    return (
        <>
            <div>
                <div className="container py-12 px-6 md:px-8">
                    <div className="text-4xl font-bold leading-9">
                        Unveiling Our Newest Narratives:
                    </div>
                    <div className="text-2xl mt-4">
                        Dive into the Latest Blogs
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
