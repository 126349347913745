import React from 'react'
import Header from './Header/Header'
import Card from './Card/Card'

function NewBlogs() {
  return (
    <>
      <Header/>
      <Card/>
    </>
  )
}

export default NewBlogs