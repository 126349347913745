import React, { useEffect } from "react";
import './HerosSection.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";
import net from 'vanta/src/vanta.net';

function HerosSection() {
  useEffect(() => {
    net({
      el: "#vanta",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: 0xfc9100,
      color2: 0xff6800,
      // size: 1.0,
      backgroundColor: 0xffffff

    });
  }, []);
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, [])
  return (
    <>
      <div className="bg-white min-h-[73vh] lg:min-h-[83vh] flex items-center justify-center" id="vanta">
        <div className="text-center">
          <h1 className="text-4xl md:text-6xl  font-bold mb-6" data-aos="fade-up">Welcome to <b className="text-[#2DC3D0] block mt-4 md-mt-0 md:inline-block"> My Wisdom Hub </b></h1>
          <p className="text-lg md:text-xl text-gray-800 mb-6 w-[90%] mx-auto md:w-full" data-aos="fade-up" data-aos-delay="200">With divine guidance of my wisdom Hub start your spiritual journey,  heal your inner self and stay connected with your higher self</p>
          <div className="flex justify-center" data-aos="fade-up" data-aos-delay="400">
            <Link to="/blog">
              <button className="px-6 py-3 mr-4 bg-[#2DC3D0] text-white rounded-md hover:bg-opacity-80 transition-colors duration-300 ease-in-out">Read Blogs</button>
            </Link>
            <Link to="/about">
              <button className="px-6 py-3 bg-black text-white rounded-md hover:bg-opacity-80 transition-colors duration-300 ease-in-out">Learn More</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default HerosSection;
