import React from 'react'
import HerosSection from './HeroSection/HerosSection'
import NewBlogs from './NewBlogs/NewBlogs'

function Home() {
  return (
    <>    
    <HerosSection/>
    <NewBlogs/>
    </>
  )
}

export default Home
