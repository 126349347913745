import React, { useEffect, useState } from 'react';
import './Content.css'
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import authorPic from '../../Assets/Rabail Sajjad.png';

const Content = () => {
  const location = useLocation();
  const [blog, setBlog] = useState(null);

  const getQueryParams = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const id = getQueryParams('id');

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.post(`https://api.mywisdomhub.com/blogs/get_blog_by_id/${id}`);
        setBlog(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching the blog:', error);
      }
    };

    if (id) {
      fetchBlog();
    }
  }, [id]);

  if (!blog) {
    return <p className='px-6 md:px-8 mt-8'>Loading...</p>;
  }

  return (
    <>
      <div className="flex fontjost flex-col lg:flex-row my-8 px-6 md:px-8">
        <div className="grid w-full text-2xl border-l-0">
          <h1 className="text-4xl font-bold my-6 text-gray-900">{blog.name}</h1>
          <div className="flex items-center">
            <div className="flex items-center mb-4 text-gray-600 text-[16px]">
              {/* <img
                src={authorPic}
                alt="Rabail Sajjad"
                className="w-10 h-10 rounded-full mr-3 object-cover"
              /> */}
              <div>
                <span className="text-gray-600">Rabail Sajjad</span>
                <span className="text-gray-500 mx-1"> - </span>
                <span className="text-gray-500">{blog.date}</span>
              </div>
            </div>
          </div>
          <div className='blogpage'>
            <div
              className="text-[16px] text-gray-600"
              dangerouslySetInnerHTML={{ __html: blog.paragraph }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
