import React from 'react'
import HeroHeader from '../../Components/HeroHeader/HeroHeader'
import ContactPage from './Contact_form'

function Contact() {
  return (
    <>
    <HeroHeader name = "Contact Us" text = "Thank you for contacting with my wisdom Hub. We welcome your inquiries, feedback and suggestions.  Feel free to reach out through this email address: rabiraza110@gmail.com" />
    <ContactPage/>
    </>
  )
}

export default Contact