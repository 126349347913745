import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
function Footer() {
  useEffect(() => {
    AOS.init();
  }, [])
  const handleLinkClick = () => {
    scroll.scrollToTop();
  }
  return (
    <>
      <footer className="bg-[#0d7b95] pt-[3rem] pb-[1.5rem] px-6 md:px-8 fontjost">
        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  gap-5 text-white font-Inter">
          {/* Brand logo... */}
          <div data-aos="fade-up" data-aos-duration="500">
            <div className="flex items-center" >
              {/* <img src={logo} alt="Logo"/> */}
              <Link to="/" className="fontlora font-semibold text-[2rem]" onClick={handleLinkClick}>
                Wisdom<span className="text-[#2DC3D0]">Hub</span>
              </Link>
            </div>
            <hr className="mt-10 mb-4 w-[3rem]" />
            {/* <div className="mt-2text-[16px] font-light">
              +447883369018
            </div> */}
            <div className="mt-2 text-[16px] font-light">
              rabiraza110@gmail.com
            </div>
            <div className="mt-2 text-[16px] font-light">
            United Kingdom, Bolton
            </div>
          </div>

          {/* About Us */}
          <div className="mt-[20px] md:mt-0 lg:pl-[0rem]" data-aos="fade-up" data-aos-duration="700">
            <h1 className="text-[27px] font-normal ml-0">ABOUT US</h1>
            <p className="mt-4 md:mt-8 ml-0 w-[80%] lg:w-[100%] ">
            Welcome to My Wisdom Hub. We invite you to join us and dive into a wealth of content covering various spiritual practices and personal growth techniques,  whether you are interested in meditation, mindfulness and energy healing. Our blogs are designed to enlighten and inspire.
            Thanks for joining us.
            </p>
          </div>

          {/* Useful Links */}
          <div className="mt-[20px] lg:mt-0 lg:pl-[5rem]" data-aos="fade-up" data-aos-duration="800">
            <h1 className="text-[25px] ml-0 font-normal"> QUCIK LINKS</h1>
            <div className="mt-4 lg:mt-8 ml-0 text-[15px] transition-all duration-300 ease-in-out hover:ml-1 " onClick={handleLinkClick}><Link to='/blog'> Blog </Link></div>
            <div className="my-1 ml-0 text-[15px] transition-all duration-300 ease-in-out hover:ml-1" onClick={handleLinkClick}><Link to='/contact'> Contact Us </Link></div>
            <div className=" ml-0 text-[15px] transition-all duration-300 ease-in-out hover:ml-1" onClick={handleLinkClick}><Link to='/about'> About Us </Link></div>
          </div>

          {/* Products*/}
          <div className="mt-[20px] lg:mt-0 lg:pl-[3rem]" data-aos="fade-up" data-aos-duration="1000">
            <h1 className="text-[27px] font-normal ml-0"> Legal </h1>
            <div className="mt-4 lg:mt-8 ml-0 text-[15px]transition-all duration-300 ease-in-out hover:ml-1" onClick={handleLinkClick}><Link to='/'>Terms of use</Link></div>
            <div className="my-1 ml-0 text-[15px] transition-all duration-300 ease-in-out hover:ml-1" onClick={handleLinkClick}><Link to='/'>Privacy Policy</Link></div>
            <div className=" ml-0 text-[15px] transition-all duration-300 ease-in-out hover:ml-1" onClick={handleLinkClick}><Link to='/'>Cookies Policy </Link></div>
            {/* <div className="border-b border-white mt-[142.5px] ml-0 -mt-2 -mr-5"></div>  */}
          </div>
        </div>
        <div >
          <hr className="mt-[3rem]" />
          <p className="text-[14px] font-light pt-5  ml-0 text-white" >&copy; COPYRIGHT 2024 MYWISDOMHUB ALL RIGHTS RESERVED & DESIGN BY <a href="https://alpharisetech.com/" target="__blank" className="hover:text-gray-300 transition-all duration-300 ease-in-out font-bold"> Alpha Rise Tech</a></p></div>
      </footer>
    </>
  );
}

export default Footer;
