import React, { useEffect } from "react";
import imageSrc from "../../Assets/spirituality.jpeg";
import "aos/dist/aos.css";
import AOS from "aos";
function Content() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease",
    });
  }, []);

  return (
    <>
      <div className="flex justify-center px-6 md:px-8">
        <div className="py-[2rem] md:py-[5rem] w-[1100px]">
          <div className="flex md:flex-row flex-col mt-5" data-aos="fade-up">
            <div className="w-full md:w-1/2 h-[250px] flex justify-center items-center relative mt-8 md:mt-0" >
              <img src={imageSrc} alt="Spirituality" />
            </div>
            <div className="w-full md:w-1/2" data-aos="fade-left">
              <div className="flex flex-col items-center justify-between mt-5 md:mt-0 md:flex-row md:items-center md:my-[30px] md:w-[calc(100%-40px)] md:ml-4 fontjost">
                <p className="text-black text-sm md:text-lg mt-5 md:mt-0 md:ml-5 ">
                  Welcome to my wisdom Hub.
                  Discover the ultimate path to self-actualization and enlightenment. We are dedicated to share our gems of wisdom with all of you. We invite you to join us and dive into a wealth of content covering various spiritual practices and personal growth techniques. whether you're interested in meditation, mindfulness and energy healing, Our blogs are designed to enlighten and inspire.
                  At my wisdom Hub, we believe that spirituality is a personal journey that can lead to profound transformation and fulfilment.
                  Thanks for joining us.
                </p>
              </div>
            </div>
          </div>
          {/* 
      <div className="flex md:flex-row-reverse flex-col-reverse mt-8" data-aos="fade-up">
        <div className="w-full md:w-1/2 h-[250px] flex justify-center items-center relative mt-8 md:mt-0">
          <svg
            className="absolute w-60 h-60"
            viewBox="0 0 200 187"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            style={{
              backgroundImage: `url(${blobBackground})`,
              backgroundSize: "cover",
            }}
          >
            <g mask="url(#mask0)">
              <path
                d="M190.312 36.4879C206.582 62.1187 201.309 102.826 182.328 134.186C163.346 165.547 130.807 187.559 100.226 186.353C69.6454 185.297 41.0228 161.023 21.7403 129.362C2.45775 97.8511 -7.48481 59.1033 6.67581 34.5279C20.9871 10.1032 59.7028 -0.149132 97.9666 0.00163737C136.23 0.303176 174.193 10.857 190.312 36.4879Z"
                fill="#2DC3D0"
              ></path>
              <image className="home__blob-img w-[90%]"xlinkHref={imageSrc}></image>
            </g>
          </svg>
        </div>
        <div className="w-full md:w-1/2" data-aos="fade-left">
          <div className="flex flex-col items-center justify-between mt-5 md:flex-row md:items-center md:my-[30px] md:w-[calc(100%-40px)] md:ml-4 fontjost">
            <p className="text-black text-sm md:text-lg mt-5 md:mt-0 md:ml-5 ">
            Welcome to Nexron, where healing meets understanding, and your journey to mental wellness begins. Our team of dedicated therapists is committed to providing compassionate and personalized therapy to help you navigate life's challenges and achieve lasting positive change.
            </p>
          </div>
        </div>
      </div>

      <div className="flex md:flex-row flex-col-reverse mt-8" data-aos="fade-up">
        <div className="w-full md:w-1/2 h-[250px] flex justify-center items-center relative mt-8 md:mt-0">
          <svg
            className="absolute w-60 h-60"
            viewBox="0 0 200 187"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            style={{
              backgroundImage: `url(${blobBackground})`,
              backgroundSize: "cover",
            }}
          >
            <g mask="url(#mask0)">
              <path
                d="M190.312 36.4879C206.582 62.1187 201.309 102.826 182.328 134.186C163.346 165.547 130.807 187.559 100.226 186.353C69.6454 185.297 41.0228 161.023 21.7403 129.362C2.45775 97.8511 -7.48481 59.1033 6.67581 34.5279C20.9871 10.1032 59.7028 -0.149132 97.9666 0.00163737C136.23 0.303176 174.193 10.857 190.312 36.4879Z"
                fill="#2DC3D0"
              ></path>
              <image className="home__blob-img w-[90%]"xlinkHref={imageSrc}></image>
            </g>
          </svg>
        </div>
        <div className="w-full md:w-1/2" data-aos="fade-left">
          <div className="flex flex-col items-center justify-between mt-5 md:flex-row md:items-center md:my-[30px] md:w-[calc(100%-40px)] md:ml-4 fontjost">
            <p className="text-black text-sm md:text-lg mt-5 md:mt-0 md:ml-5 ">
            Welcome to Nexron, where healing meets understanding, and your journey to mental wellness begins. Our team of dedicated therapists is committed to providing compassionate and personalized therapy to help you navigate life's challenges and achieve lasting positive change.
            </p>
          </div>
        </div>
      </div> */}

        </div>
      </div>
    </>
  );
}

export default Content;