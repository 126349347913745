import React, { useEffect, useState } from "react";
import { FaBars, FaTimes } from 'react-icons/fa';
import './Navbar.css'
import logo from "../../Assets/logo.svg";
import AOS from 'aos';
import 'aos/dist/aos.css'
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

function Navbar() {
  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  const [navmenu, setNavMenu] = useState(false);

  const handleLinkClick = () => {
    scroll.scrollToTop();
  }

  function setNavbar() {
    setNavMenu(!navmenu);
  }

  return (
    <>
      <nav className="bg-white text-black flex justify-between items-center py-[2rem] sticky top-0 z-[999] px-6 md:px-8">
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-center items-center">
            <img src={logo} alt="Logo" />
            <a href="/" className="text-[1.6rem] md:text-[2rem] font-bold ml-3 fontlora">Wisdom<span className="text-[#2DC3D0]">Hub</span></a>
          </div>
          <div>
            <div className="lg:hidden" onClick={setNavbar} >
              {navmenu ? (
                <FaTimes className={`text-black text-[1.5rem] transition-opacity duration-500 ease-in-out navbaranimation`} />
              ) : (
                <FaBars className={`text-black text-[1.5rem]`} />
              )}
            </div>
            {/* Desktop nav menu */}
            <ul className="hidden lg:flex space-x-4 fontjost gap-8 items-center justify-center">
              <li onClick={handleLinkClick}><Link to="/" className="hover:font-bold text-[17px]">Home</Link></li>
              <li onClick={handleLinkClick}><Link to="/blog" className="hover:font-bold text-[17px]">Blog</Link></li>
              <li onClick={handleLinkClick}><Link to="/about" className="hover:font-bold text-[17px]">About Us</Link></li>
              <li onClick={handleLinkClick}><Link to="/contact" className="hover:font-bold text-[17px]">Contact Us</Link></li>
              <li onClick={handleLinkClick}><button className="px-6 py-3 mr-4 bg-[#2DC3D0] text-white rounded-md hover:bg-opacity-80 transition-colors duration-300 ease-in-out">Login</button></li>
            </ul>

            {/* Mobile Nav Menu */}
            <ul className={`${navmenu ? 'flex' : 'hidden'} lg:hidden flex-col items-center justify-center fontjost fixed right-8 w-[14rem] bg-[#0d7b95] text-white rounded py-6 px-4 mt-3 transition-opacity duration-500 ease-in-out navbaranimationmobile` }>
              <li onClick={handleLinkClick} className="mb-4"><Link to="/" className="hover:font-bold text-[17px]">Home</Link></li>
              <li onClick={handleLinkClick} className="mb-4"><Link to="/blog" className="hover:font-bold text-[17px]">Blog</Link></li>
              <li onClick={handleLinkClick} className="mb-4"><Link to="/about" className="hover:font-bold text-[17px]">About Us</Link></li>
              <li onClick={handleLinkClick} className="mb-4"><Link to="/contact" className="hover:font-bold text-[17px]">Contact Us</Link></li>
              <li onClick={handleLinkClick} className="mt-2"><button className="bg-[#2DC3D0] px-[20px] py-[7px] rounded-[5px]"><Link to="/" className="hover:font-bold text-[17px]">Login</Link></button></li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
