import './index.css'
import React from 'react';
import { Routes, Route} from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home'
import Blog from './Pages/Blog/Blog'
import BlogPage from './Pages/BlogPage/BlogPage'
import Contact from './Pages/Contact/Contact';
import About from './Pages/About/About'

function App() {
  return (
    <>
    <div className='mainbodylayout'>
      <Navbar/>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/blog-page" element={<BlogPage />} />
      </Routes>
      <Footer/>
    </div>
    </>
  );
}
export default App;

