import React from 'react'

function HeroHeader() {
    return (
        <>
            <div className="mb-[4rem] px-6 md:px-8">
                <h1 className="lg:w-[50%] font-semibold text-[36px] md:text-[50px] fontjost my-7 leading-[50px]">
                    My Wisdom Spirtual Healing BLOG
                </h1>
            </div>
        </>
    )
}

export default HeroHeader
