import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import axios from 'axios';
import Rabail_Sajjad from '../../../../Assets/Rabail Sajjad.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { animateScroll as scroll } from "react-scroll";

function Card() {
  const handleLinkClick = () => {
    scroll.scrollToTop();
  }

  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    AOS.init();
  }, [])
  useEffect(() => {
    AOS.init();
    axios.post('https://api.mywisdomhub.com/blogs/get_all_blogs')
      .then(response => {
        console.log(response)
        setBlogs(response.data);
      })
      .catch(error => {
        console.error('Error fetching blogs:', error);
      });
  }, []);
  return (
    <>
      <div className='mb-16'>
        <div className="px-6 md:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {blogs.map((blog, key) => (
              <>
                <div className="w-full mb-4 md:mb-0" data-aos="fade-up" data-aos-duration={500}>
                  <div className="bg-white rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 ease-in-out overflow-hidden " onClick={handleLinkClick}>
                    <Link to={`/blog-page?id=${blog.id}`}>
                      <div className="p-4">
                        <Link to={`/blog-page?id=${blog.id}`}>
                          <h2 className="text-2xl font-bold mb-2 cursor-pointer hover:underline">{blog.name}</h2>
                        </Link>
                        <div className="flex items-center mb-2 text-gray-600">
                          {/* <img
                            src={Rabail_Sajjad}
                            alt="Rabail Sajjad"
                            className="w-8 h-8 rounded-full mr-3 object-cover"
                          /> */}
                          <div>
                            <span>Rabail Sajjad</span>
                            <span className="text-gray-500 ml-1">-</span>
                            <span className="text-gray-500 ml-1">{blog.date}</span>
                          </div>
                        </div>
                        <p className="text-gray-600">{blog.description}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Card
