import React from 'react'
import HeroHeader from './HeroHeader'
import BackToAllPosts from './backButton'
import Content from './Content'

function BlogPage() {
    return (
        <>
            <HeroHeader />
            <BackToAllPosts />
            <Content />
        </>
    )
}

export default BlogPage