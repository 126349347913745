import React from "react";
import HeroHeader from '../../Components/HeroHeader/HeroHeader'
import Content from './Content'

function About() {
  return (
    <>    
    <HeroHeader name = "About Us" />
    <Content/>
    </>
  );
}

export default About;