import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
function HeroHeader(props) {
  useEffect(()=>{
    AOS.init();
      },[])
  return (
    
    <>
      <div className="bg-gradient-to-b from-white to-[rgb(137,237,246)] py-20 text-center px-6 md:px-8" >
        <h1 className="text-black text-[35px] md:text-[45px] font-bold fontjost mb-4" data-aos="fade-up" data-aos-duration="500">
          {props.name}
        </h1>
        <p className="text-black md:text-base fontjost" data-aos="fade-up" data-aos-duration="700">
          {props.text}
        </p>
      </div>
    </>
  )
}

export default HeroHeader