import React from 'react';
import { Link } from "react-router-dom";
import { SlArrowLeftCircle } from "react-icons/sl";

const BackToAllPosts = () => {
  return (
    <div className='px-6 md:px-8'>
    <Link
      to="/blog"
      className="px-6 py-3 mr-4 bg-[#2DC3D0] text-white rounded-md hover:bg-opacity-80 transition-colors duration-300 ease-in-out flex items-center w-[12rem]"
    >
      <SlArrowLeftCircle/>
      <p className='pl-2'>Back to all Blogs</p>
      
    </Link>
    </div>
  );
};

export default BackToAllPosts;